import * as React from 'react'
import PropTypes from 'prop-types'
import Pagination from '../products/Pagination'
import { BookOpenIcon, LockClosedIcon, PlusCircleIcon } from '@heroicons/react/24/solid'
import { PlusCircle } from 'heroicons-react'

const Spread = ({
  spread,
  ProductCard,
  generateSpread,
  holdItem,
  removeItem,
  lockItem,
  currentSpread,
  setCurrentSpread,
  spreads,
  changeSpreadRow,
  updatePageNumber,
  updateItem,
  isUsed,
  deleteSpreads
}) => {
  let maxCols = 0
  for (let page of spread) {
    for (let row of page.rows) {
      if (row > maxCols) maxCols = row
    }
  }
  const [highlightRow, setHighlightRow] = React.useState(false)

  return (
    <div className="grid grid-cols-2">
      {spread.map((page, pageIndex) => {
        let lastRow = 0
        const getItemPosition = index => {
          let itemsCounted = 0
          for (let row = 0; row < page.rows.length; row++) {
            const rowCount = page.rows[row]
            if (index < itemsCounted + rowCount) {
              // Found the row! Its column is the offset inside the row.
              return { row: row + 1, col: index - itemsCounted + 1, maxCol: rowCount }
            }
            itemsCounted += rowCount
          }
          return { row: page.rows.length, col: 1, maxCol: page.rows[page.rows.length - 1] }
        }

        return (
          <div
            key={page.name}
            className="flex flex-row flex-wrap p-5 relative border shadow-xl gap-x-3 gap-y-5 pb-14"
          >
            {page.items.length === 0 && (
              <div className="w-full min-h-[400px] flex col-span-4 row-span-3 text-gray-400 items-center justify-center gap-4">
                This Page Is Empty
              </div>
            )}
            <div
              className={
                'w-12 h-full from-gray-200 to-white absolute top-0 ' +
                (pageIndex > 0
                  ? 'bg-gradient-to-r left-0'
                  : 'bg-gradient-to-l right-0 border-r border-gray-250')
              }
            />
            {page.items.map((item, pageItemIndex) => {
              const pos = getItemPosition(pageItemIndex)
              const newRow = lastRow !== pos.row
              const lockRow = row => {
                let rowItems = page.items.filter(
                  item => getItemPosition(page.items.indexOf(item)).row === row
                )
                if (rowItems.some(item => item.locked === undefined)) {
                  // lock all items in the row
                  //
                  rowItems
                    .filter(item => item.locked === undefined)
                    .forEach(item => {
                      lockItem(item.sku)
                    })
                } else
                  rowItems.forEach(item => {
                    lockItem(item.sku)
                  })
              }
              lastRow = pos.row
              return (
                <div
                  key={item.id}
                  className="flex-grow-0"
                  style={{ width: `calc(100% / ${pos.maxCol} - 12px)` }}
                >
                  {newRow && (
                    <div
                      onMouseEnter={() => setHighlightRow(`${pos.row},${pageIndex}`)}
                      onMouseLeave={() => setHighlightRow(false)}
                      className={
                        (pageIndex === 0 ? '-left-16 text-left' : '-right-16 text-right') +
                        ' absolute w-14 h-8 group z-[100] cursor-pointer '
                      }
                    >
                      <span className="text-xs">COLUMNS</span>
                      <select
                        value={pos.maxCol}
                        className={'border w-14 h-8 rounded-md px-2'}
                        onChange={e => changeSpreadRow(pageIndex, pos.row, e.target.value)}
                      >
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map(columns => (
                          <option key={columns} value={columns}>
                            {columns === 0 ? '\u274C' : columns}{' '}
                          </option>
                        ))}
                      </select>

                      <button onClick={() => lockRow(pos.row)}>
                        <LockClosedIcon className="w-14 h-8 bg-gray-400 hover:bg-orange-500 text-white shadow-lg p-1" />
                      </button>
                    </div>
                  )}
                  {highlightRow === `${pos.row},${pageIndex}` && (
                    <div
                      className={`absolute left-0 w-full bg-gray-200 rounded-md -translate-y-4`}
                      style={{ height: 'calc(' + Math.round(100 / page.rows.length) + '% - 30px)' }}
                    />
                  )}
                  <ProductCard
                    product={item}
                    key={item.id}
                    id={item.id}
                    lockItem={lockItem}
                    holdItem={holdItem}
                    removeItem={removeItem}
                    updateItem={updateItem}
                    aspectRatio={`${maxCols}/${pos.maxCol}`}
                    isUsed={isUsed(item, page.number)}
                  />
                </div>
              )
            })}
            <input
              id="left-page-num"
              type="number"
              className={
                'w-20 absolute bottom-5 p-1 ' +
                (pageIndex > 0
                  ? 'text-right right-5 pointer-events-none'
                  : 'left-5 hover:bg-gray-100')
              }
              placeholder="Page #"
              value={page.number}
              onChange={e => updatePageNumber(pageIndex, parseInt(e.target.value))}
            />
            <button
              onClick={() => changeSpreadRow(pageIndex, page.rows.length + 1, maxCols)}
              className={
                (pageIndex > 0 ? '-right-14' : '-left-14') +
                ' text-emerald-700 hover:text-emerald-600 duration-200 absolute bottom-0 '
              }
            >
              <PlusCircleIcon className="size-12" />
            </button>
          </div>
        )
      })}
      <div className="col-span-2 w-full flex flex-col items-center gap-4 mb-10">
        <div className="flex flex-row gap-4">
          <Pagination
            pageInfo={{ currentPage: currentSpread + 1, perPage: 1, totalCount: spreads.length }}
            onClick={p => setCurrentSpread(p - 1)}
          />
          <button onClick={() => deleteSpreads()} className="bg-red-600 text-white px-4 py-2">delete</button>
        </div>
        <button
          onClick={generateSpread}
          className="w-[15rem] h-12 bg-green-600 ring-2 ring-offset-2 hover:ring-offset-4 hover:scale-[102%] hover:-translate-y-1 hover:shadow-lg ring-lime-400 duration-200 text-white text-lg font-bold flex flex-row items-center justify-center gap-2 group"
        >
          <BookOpenIcon className="size-8 group-hover:animate-bounce" /> GENERATE SPREAD
        </button>
      </div>
    </div>
  )
}
Spread.propTypes = {
  spread: PropTypes.array,
  ProductCard: PropTypes.func,
  generateSpread: PropTypes.func,
  holdItem: PropTypes.func,
  removeItem: PropTypes.func,
  currentSpread: PropTypes.number,
  setCurrentSpread: PropTypes.func,
  spreads: PropTypes.array,
  changeSpreadRow: PropTypes.func,
  updatePageNumber: PropTypes.func,
  lockItem: PropTypes.func,
  updateItem: PropTypes.func,
  isUsed: PropTypes.func,
  deleteSpreads: PropTypes.func
}

export default Spread
